import * as React from "react";
import "../../gatsby-browser.js";
import Navbar from "../component/Navbar/Navbar";
import Footer from "../component/Footer/Footer";
import { motion } from "framer-motion";
import { lan } from "../lan/service";
import Services from "../static/img/img/work.png";
// import { lan_data } from "../component/Local/local";
import Illustration from "../static/img/socialMediaMarketing/smmIllustration.svg";
import Facebook from "../static/img/socialMediaMarketing/facebook-icon.svg"
import Google from "../static/img/socialMediaMarketing/google-icon.svg"
import Influencer from "../static/img/socialMediaMarketing/influencer-icon.svg"
import Instagram from "../static/img/socialMediaMarketing/insta-icon.svg"
import Sales from "../static/img/socialMediaMarketing/sales-icon.svg"
import Verification from "../static/img/socialMediaMarketing/verification-icon.svg"
import MetaTags from "../component/MetaTags.jsx";

class Smo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      loading: true,
      name: [],
      email: [],
      phone: [],
      message: [],
    };
  }

  getName = (e) => {
    this.setState({
      name: e.target.value,
    });
  };

  getEmail = (e) => {
    this.setState({
      email: e.target.value,
    });
  };

  getPhone = (e) => {
    this.setState({
      phone: e.target.value,
    });
  };

  getMessage = (e) => {
    this.setState({
      message: e.target.value,
    });
  };

  render() {
    const pageVariants = {
      initial: {
        opacity: 0,
      },
      in: {
        opacity: 1,
      },
      out: {
        opacity: 0,
      },
    };

    const { data, loading } = this.state;

    return (
      <>
        <Navbar />
        <motion.div
          initial="initial"
          animate="in"
          exit="out"
          variants={pageVariants}
        >
          <section id="mobile-tech-banner" className="howwedo">
            <div className="banner-text">
              <h1 className="highlighted-text">
                Make with our cutting-edge
                <br />
                Social Media Marketing
              </h1>
              <p>{lan.en["mobile"]["head"][0]}</p>
            </div>
            <img src={Illustration} alt="" />
          </section>

          <div className="container">
            <div className="row about-extra">
              <div className="mobile-desc" align={"justify"}>
                <h2 align={"center"} className="highlighted-text">
                  {" "}
                  About <span className="light-text"> Social Media Marketing</span>{" "}
                </h2>

                <p className={"service_about"}>
                  {lan.en["mobile"]["about"][0]}
                </p>
              </div>

              {/* <div align={"center"}>
                <h2 className="highlighted-text"> About<span className="light-text"> Social Media Marketing</span> </h2>
              </div>
              <div className="col-lg-6 wow fadeInUp">
                <img src={Services} className="img-fluid" alt="" width="400" />
              </div>
              <div className="col-lg-6 wow fadeInUp pt-5 pt-lg-0">
                <p className={"service_about"}>
                  {lan.en["mobile"]["about"][0]}
                </p>
              </div> */}
            </div>
          </div>

          <section id="services" className="advance">
            <header className="section-header">
              <h3 className="light-text"> End-to-End Mobile Development </h3>
            </header>
            <br />

            <div className="pointers-container">
              <div
                className="wow bounceInUp "
                data-wow-duration="1.4s"
              >
                <div className="row">
                  <div
                    className="wow bounceInUp "
                    data-wow-duration="1.4s"
                  >
                    <div className="box blank_service">
                      <p>
                        <div className={"row"}>
                          <div className={"col-sm-12"}>
                            <span id={"one_point"}> 1. </span>{" "}
                            {lan.en["mobile"]["service"][0]}
                          </div>
                        </div>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* ========================================  type =========================================== */}


          <section id="services" className="processing">
              <div className={"row"}>
                <div align={"center"}>
                  <br /> <br />
                  <br />
                  <h2 className="light-text">Services We Give</h2>
                </div>
                <h4 className="highlighted-text" align="center">
                  Social Media Marketing Service
                </h4>
                <br /> <br />
                <div className="tech-container">
                  <div className="box ">
                    <div align="center">
                      <img
                        src={Facebook}
                        alt=""
                        className="img-fluid"
                        width="100"
                      />
                    </div>{" "}
                    <br />
                    <div align="center">
                      <h4 className="tech_head">Facebook Marketing</h4>
                    </div>
                  </div>
                  <div className="box ">
                    <div align="center">
                      <img
                        src={Instagram}
                        alt=""
                        className="img-fluid"
                        width="100"
                      />
                    </div>{" "}
                    <br />
                    <div align="center">
                      <h4 className="tech_head">Instagram Marketing</h4>
                    </div>
                  </div>{" "}
                  <div className="box ">
                    <div align="center">
                      <img src={Google} alt="" className="img-fluid" width="100" />
                    </div>{" "}
                    <br />
                    <div align="center">
                      <h4 className="tech_head">Re-Marketing</h4>
                    </div>
                  </div>{" "}
                  <div className="box ">
                    <div align="center">
                      <img
                        src={Influencer}
                        alt=""
                        className="img-fluid"
                        width="100"
                      />
                    </div>{" "}
                    <br />
                    <div align="center">
                      <h4 className="tech_head">Influencer Marketing</h4>
                    </div>
                  </div>{" "}
                  <div className="box ">
                    <div align="center">
                      <img
                        src={Sales}
                        alt=""
                        className="img-fluid"
                        width="100"
                      />
                    </div>{" "}
                    <br />
                    <div align="center">
                      <h4 className="tech_head">Lead Generation / Sales Campaigns</h4>
                    </div>
                  </div>{" "}
                  <div className="box ">
                    <div align="center">
                      <img
                        src={Verification}
                        alt=""
                        className="img-fluid"
                        width="100"
                      />
                    </div>{" "}
                    <br />
                    <div align="center">
                      <h4 className="tech_head">Social Media Verification</h4>
                    </div>
                  </div>
                </div>
            </div>
          </section>
{/* 
          <section id="services" className="section-bg processing">
            <div className={"container"}>
              <div className={"row"}>
                <div align={"center"}>
                  <h2 className={"process_head"}> Services We Give </h2>
                </div>
                <br />
                <br />
                <br />
                <div className={"col-sm-12"}>
                  <div className={"table-responsive"}>
                    <table className="table ">
                      <thead></thead>

                      <tbody>
                        <tr align={"center"}>
                          <th scope="row">Social Media Marketing Service </th>

                          <td>Facebook Marketing</td>
                          <td>Instagram Marketing</td>
                          <td>Re marketing</td>
                          <td>Influencer Marketing</td>
                          <td>Lead Generation / Sales Campaigns</td>
                          <td>Social Media Verification</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section> */}

          <br />
          <br />

          <Footer />
        </motion.div>
        <MetaTags title={"SMO | Koderbox"} />
      </>
    );
  }
}

export default Smo;
